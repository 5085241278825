import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import QRCode from 'react-qr-code';

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchQR } from '../actions/qrs'

class QRDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isQRCodeLoaded: false,
    };
    this.handleQRCodeLoad = this.handleQRCodeLoad.bind(this);
    this.downloadQRCode = this.downloadQRCode.bind(this);
  }

  componentDidMount() {
    this.props.fetchQR(this.props.match.params.id)
  }

  handleQRCodeLoad(e) {
    this.setState({
      isQRCodeLoaded: true,
    });
  }

  downloadQRCode(e) {


    const svgData = document.getElementById("qrimage");
    const svgBlob = new Blob([svgData.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = 'qrcode.svg';
    downloadLink.click();
    URL.revokeObjectURL(svgUrl);
  }

  returnQrType(type) { 
    
    switch(type) {
      case 'pay': return 'Pago';
      case 'free': return 'Gratis';
      case 'test': return 'Test';
    }

  }

  returnQrMode(mode) { 
    
    switch(mode) {
      case 0: return '24H CON PIN';
      case 1: return 'OTO NO PIN';
      case 2: return 'BAQUEIRA';
    }

  }

  /*downloadQRCode(e) {

    const { qr } = this.props


    const canvas = document.getElementById("qrimage");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrimage.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }*/

  render() {
    const { role, type, qr, error, loading } = this.props
    const { isQRCodeLoaded } = this.state

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!qr || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">QR </span>
              <span className="text-vw-dark">{qr.Code}</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-4 col-md-4 text-right">
              <Link to={`/qrs/${qr._id}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col">
            <Box title="Resumen" icon="sliders-h">
              <div className="row">
                <div className="col col-12 col-xs-12 col-md-4">
                  <PropertyLabel name="Código"
                    value={qr.Code} />
                  <PropertyLabel name="Cliente"
                    value={qr.Customer.Fullname} />
                  <PropertyLabel name="Zona"
                    value={qr.Zone} />
                  <PropertyLabel name="Tipo"
                    value={this.returnQrType(qr.Type)} />
                  <PropertyLabel name="Modo"
                    value={this.returnQrMode(qr.Mode)} />
                  <PropertyLabel name="URL"
                    value={qr.Url} />
                </div>
                <div className="col col-12 col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                  <QRCode value={qr.Url} id="qrimage" renderAs="canvas" size={256} level={"H"} includeMargin={true} onLoad={e => this.handleQRCodeLoad(e)} />
                </div>
                <div className="col col-12 col-xs-12 col-md-4">
                  {isQRCodeLoaded &&
                    <button className="btn bg-dark text-white mr-1" onClick={e => this.downloadQRCode(e)}>
                      <i className="fas fa-download mr-1"></i>Descargar QR Loaded
                    </button>
                  }
                  <button className="btn bg-dark text-white mr-1" onClick={e => this.downloadQRCode(e)}>
                    <i className="fas fa-download mr-1"></i>Descargar QR
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  qr: state.qrs.qr,
  loading: state.qrs.loading,
  error: state.qrs.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchQR: bindActionCreators(fetchQR, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QRDetailsView)
