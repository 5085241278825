import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'

import SalesFilterForm from '../forms/SalesFilterForm'
import { fetchSales, fetchSalesCSV } from '../actions/sales'
import { getLockersName } from '../utils/machines'

class InternalSalesView extends React.Component {
  constructor(props) {
    super(props)

    this.handleSalesCSV = this.handleSalesCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchSales('', '', today, today, '', '', '')
  }

  computeSalesTotalsSale() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    const itemIndices = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 1],
      ['LOCKERBWS', 2],
      ['LOCKERCWS', 3],
      ['LOCKERBNM', 4],
      ['LOCKERCNM', 5],
      ['LOCKERBWM', 6],
      ['LOCKERCWM', 7],
      ['LOCKERBNL', 8],
      ['LOCKERCNL', 9],
      ['LOCKERBWL', 10],
      ['LOCKERCWL', 11],
      ['LOCKERBNXL', 12],
      ['LOCKERCNXL', 13],
      ['LOCKERBWXL', 14],
      ['LOCKERCWXL', 15],
      ['LOCKERBN2XL', 16],
      ['LOCKERCN2XL', 17],
      ['LOCKERBW2XL', 18],
      ['LOCKERCW2XL', 19],
      ['LOCKERBN3XL', 20],
      ['LOCKERCN3XL', 21],
      ['LOCKERBW3XL', 22],
      ['LOCKERCW3XL', 23],
      ['TUMBONAS', 24],
      ['FLOTADORES', 25],
      ['FLOTADORESHALFDAY', 26],
      ['FASTPASS', 27]
    ])

    let total = 0
    let totalIva = 0
    let totalBase = 0

    let totalsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let amount = 0

    let amountsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let totalsPerItem = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {

      if (sale.Customer.BusinessModel === 'SALE') {
        let totalsPerSaleMethod = totalsPerMethod.get(sale.PaymentMethod)
        let amountsPerSaleMethod = amountsPerMethod.get(sale.PaymentMethod)
        let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

        if (sale.Items) {
          for (let item of sale.Items) {

            let itemTotal = 0
            let itemAmount = 0

            itemTotal = item.price
            itemAmount = item.price

            if (sale.Amount != 0) {
              total += itemTotal
              totalIva += itemTotal - (itemTotal / (1 + (sale.Customer.Invoice_tax / 100)))
              totalBase += itemTotal / (1 + (sale.Customer.Invoice_tax / 100))

              if (sale.Amount < 0) {
                amountsPerSaleMethod = amountsPerMethod.get("DEVOLUTION")
                totalsPerSaleMethod = totalsPerMethod.get("DEVOLUTION")
                qtyPerSaleMethod = qtyPerMethod.get("DEVOLUTION")
              }

              totalsPerSaleMethod[itemIndices.get(item.item)] += itemTotal
              totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)

              amount += itemAmount
              amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
            }

            qtyPerSaleMethod[itemIndices.get(item.item)] += Math.abs(item.qty)

          }
        }

        if (sale.Amount < 0) {
          amountsPerMethod.set("DEVOLUTION", amountsPerSaleMethod)
          totalsPerMethod.set("DEVOLUTION", totalsPerSaleMethod)
          qtyPerMethod.set("DEVOLUTION", qtyPerSaleMethod)
        } else {
          amountsPerMethod.set(sale.PaymentMethod, amountsPerSaleMethod)
          totalsPerMethod.set(sale.PaymentMethod, totalsPerSaleMethod)
          qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
        }

      }
    }

    return {
      total: total,
      totalIva: totalIva,
      totalBase: totalBase,
      totalsPerMethod: totalsPerMethod,
      totalsPerItem: totalsPerItem,
      amount: amount,
      amountsPerMethod: amountsPerMethod,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotalsSale() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {
      if (sale.Customer.BusinessModel === 'SALE') {
        if (sale.Amount != 0) {
          for (let item of sale.Items) {

            let itemTotal = item.qty

            totalQty += itemTotal
            totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
          }
        }
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  computeSalesTotalsRevenue() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    const itemIndices = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 1],
      ['LOCKERBWS', 2],
      ['LOCKERCWS', 3],
      ['LOCKERBNM', 4],
      ['LOCKERCNM', 5],
      ['LOCKERBWM', 6],
      ['LOCKERCWM', 7],
      ['LOCKERBNL', 8],
      ['LOCKERCNL', 9],
      ['LOCKERBWL', 10],
      ['LOCKERCWL', 11],
      ['LOCKERBNXL', 12],
      ['LOCKERCNXL', 13],
      ['LOCKERBWXL', 14],
      ['LOCKERCWXL', 15],
      ['LOCKERBN2XL', 16],
      ['LOCKERCN2XL', 17],
      ['LOCKERBW2XL', 18],
      ['LOCKERCW2XL', 19],
      ['LOCKERBN3XL', 20],
      ['LOCKERCN3XL', 21],
      ['LOCKERBW3XL', 22],
      ['LOCKERCW3XL', 23],
      ['TUMBONAS', 24],
      ['FLOTADORES', 25],
      ['FLOTADORESHALFDAY', 26],
      ['FASTPASS', 27]
    ])

    let total = 0
    let totalIva = 0
    let totalBase = 0
    let totalComission = 0
    let totalComissionIva = 0
    let totalComissionBase = 0

    let totalsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let amount = 0

    let amountsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let totalsPerItem = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {

      if (sale.Customer.BusinessModel === 'REVENUE SHARE') {
        let totalsPerSaleMethod = totalsPerMethod.get(sale.PaymentMethod)
        let amountsPerSaleMethod = amountsPerMethod.get(sale.PaymentMethod)
        let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

        if (sale.Items) {
          for (let item of sale.Items) {

            let itemTotal = 0
            let itemAmount = 0

            itemTotal = item.price
            itemAmount = item.price

            if (sale.Amount != 0) {
              total += itemTotal
              totalIva += itemTotal - (itemTotal / (1 + (sale.Customer.Invoice_tax / 100)))
              totalBase += itemTotal / (1 + (sale.Customer.Invoice_tax / 100))

              sale.Customer.Items.forEach(custitem => {
                if (custitem.item === item.item) {
                  totalComission += (itemTotal * (custitem.comission / 100));
                  totalComissionIva += (itemTotal - (itemTotal / (1 + (sale.Customer.Invoice_tax / 100)))) * (custitem.comission / 100);
                  totalComissionBase += (itemTotal / (1 + (sale.Customer.Invoice_tax / 100))) * (custitem.comission / 100);
                }
              });

              if (sale.Amount < 0) {
                amountsPerSaleMethod = amountsPerMethod.get("DEVOLUTION")
                totalsPerSaleMethod = totalsPerMethod.get("DEVOLUTION")
                qtyPerSaleMethod = qtyPerMethod.get("DEVOLUTION")
              }

              totalsPerSaleMethod[itemIndices.get(item.item)] += itemTotal
              totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)

              amount += itemAmount
              amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
            }

            qtyPerSaleMethod[itemIndices.get(item.item)] += Math.abs(item.qty)

          }
        }

        if (sale.Amount < 0) {
          amountsPerMethod.set("DEVOLUTION", amountsPerSaleMethod)
          totalsPerMethod.set("DEVOLUTION", totalsPerSaleMethod)
          qtyPerMethod.set("DEVOLUTION", qtyPerSaleMethod)
        } else {
          amountsPerMethod.set(sale.PaymentMethod, amountsPerSaleMethod)
          totalsPerMethod.set(sale.PaymentMethod, totalsPerSaleMethod)
          qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
        }

      }
    }

    return {
      total: total,
      totalIva: totalIva,
      totalBase: totalBase,
      totalComission: totalComission,
      totalComissionIva: totalComissionIva,
      totalComissionBase: totalComissionBase,
      totalsPerMethod: totalsPerMethod,
      totalsPerItem: totalsPerItem,
      amount: amount,
      amountsPerMethod: amountsPerMethod,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotalsRevenue() {
    const { sales } = this.props

    if (!sales) {
      return
    }

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {
      if (sale.Customer.BusinessModel === 'REVENUE SHARE') {
        if (sale.Amount != 0) {
          for (let item of sale.Items) {

            let itemTotal = item.qty

            totalQty += itemTotal
            totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
          }
        }
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  handleSalesCSV() {
    const { fetchSalesCSV, form } = this.props

    fetchSalesCSV(form.SalesFilterForm.values.Machine,
      form.SalesFilterForm.values.Customer,
      form.SalesFilterForm.values.StartDate,
      form.SalesFilterForm.values.EndDate)
  }

  salesTotalRow({ label, methods, totals, currency }) {
    return (
      <tr>
        <th scope="row"><h6 className="text-left text-vw-dark">{label}</h6></th>
        {methods.map(method => (
          <th scope="row" key={method}>
            {`${totals.amountsPerMethod.get(method).toLocaleString("es-ES", { style: "currency", currency })} / ${totals.qtyPerMethod.get(method)} u`}
          </th>
        ))}
      </tr>
    );
  }

  anyItemSale(totals, index) {

    console.log("Totals: ", totals);
    if (
      totals.amountsPerMethod.get('CASH')[index] > 0 ||
      totals.amountsPerMethod.get('TPV')[index] > 0 ||
      totals.amountsPerMethod.get('TOKEN')[index] > 0 ||
      totals.amountsPerMethod.get('DEVOLUTION')[index] > 0 ||
      totals.amountsPerMethod.get('QR')[index] > 0 ||
      totals.amountsPerMethod.get('PDA')[index] > 0 ||
      totals.amountsPerMethod.get('FREE')[index] > 0||
      totals.amountsPerMethod.get('CREDIT')[index] > 0
    ) {
      console.log("true");
      return true
    } else {
      console.log("false");
      return false
    }
  }

  renderSalesTotalsSale() {
    const { sales, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotalsSale()
    let totalsQty = this.computeItemsTotalsSale()

    const itemsArray = [
      "LOCKERBNS", "LOCKERCNS", "LOCKERBWS", "LOCKERCWS",
      "LOCKERBNM", "LOCKERCNM", "LOCKERBWM", "LOCKERCWM",
      "LOCKERBNL", "LOCKERCNL", "LOCKERBWL", "LOCKERCWL",
      "LOCKERBNXL", "LOCKERCNXL", "LOCKERBWXL", "LOCKERCWXL",
      "LOCKERBN2XL", "LOCKERCN2XL", "LOCKERBW2XL", "LOCKERCW2XL",
      "LOCKERBN3XL", "LOCKERCN3XL", "LOCKERBW3XL", "LOCKERCW3XL",
      "TUMBONAS", "FLOTADORES", "FLOTADORESHALFDAY", "FASTPASS"
    ];

    return (
      <div className="mb-3 p-3 bg-white box-shadow rounded text-vw-dark text-center">
        <div className="row justify-content-center">
          <h5>MÁQUINAS VENTA:</h5>
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>TOTAL:</h4>
            <h5 className="text-vw-light">{`${totals.total.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>BASE:</h4>
            <h5 className="text-vw-green">{`${totals.totalBase.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>IVA:</h4>
            <h5 className="text-vw-red">{`${totals.totalIva.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col"><h6 className="text-white">CASH</h6></th>
                <th scope="col"><h6 className="text-white">TPV</h6></th>
                <th scope="col"><h6 className="text-white">QR</h6></th>
                <th scope="col"><h6 className="text-white">PDA</h6></th>
                <th scope="col"><h6 className="text-white">GRATIS</h6></th>
                <th scope="col"><h6 className="text-white">CREDITO</h6></th>
                <th scope="col"><h6 className="text-white">MANUAL</h6></th>
                <th scope="col"><h6 className="text-white">DEVOLUCIONES</h6></th>
                <th scope="col"><h6 className="text-white">TOTAL</h6></th>
              </tr>
            </thead>
            <tbody>
              {
                itemsArray.map((item, index) => {
                  if (this.anyItemSale(totals, index)) {
                    return (
                      <tr key={item}>
                        <th scope="row"><h6 className="text-left text-vw-dark">{getLockersName(item)}</h6></th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('CASH')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('CASH')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TPV')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TPV')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('QR')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('QR')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('PDA')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('PDA')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('FREE')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('FREE')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('CREDIT')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('CREDIT')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TOKEN')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TOKEN')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('DEVOLUTION')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('DEVOLUTION')[index]} u`}</th>
                        <th scope="row">{`${(totals.totalsPerItem.get(item)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totalsQty.totalsPerItemQty.get(item)} u`}</th>
                      </tr>
                    );
                  }
                  return null;
                })
              }
              <tr>
                <th scope="row"><h5 className="text-left text-vw-dark">TOTALES</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('CASH').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TPV').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('QR').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('PDA').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('FREE').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('CREDIT').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TOKEN').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('DEVOLUTION').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderSalesTotalsRevenue() {
    const { sales, error, loading, auth } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!sales || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotalsRevenue()
    let totalsQty = this.computeItemsTotalsRevenue()

    const itemsArray = [
      "LOCKERBNS", "LOCKERCNS", "LOCKERBWS", "LOCKERCWS",
      "LOCKERBNM", "LOCKERCNM", "LOCKERBWM", "LOCKERCWM",
      "LOCKERBNL", "LOCKERCNL", "LOCKERBWL", "LOCKERCWL",
      "LOCKERBNXL", "LOCKERCNXL", "LOCKERBWXL", "LOCKERCWXL",
      "LOCKERBN2XL", "LOCKERCN2XL", "LOCKERBW2XL", "LOCKERCW2XL",
      "LOCKERBN3XL", "LOCKERCN3XL", "LOCKERBW3XL", "LOCKERCW3XL",
      "TUMBONAS", "FLOTADORES", "FLOTADORESHALFDAY", "FASTPASS"
    ];

    return (
      <div className="mb-3 p-3 bg-white box-shadow rounded text-vw-dark text-center">
        <div className="row justify-content-center">
          <h5>REVENUE SHARE</h5>
        </div>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>TOTAL:</h4>
            <h5 className="text-vw-light">{`${totals.total.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>BASE:</h4>
            <h5 className="text-vw-green">{`${totals.totalBase.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>IVA:</h4>
            <h5 className="text-vw-red">{`${totals.totalIva.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
        </div>
        <hr className="bg-vw-light-fat" />
        <div className="row justify-content-center">
          <h5>COMISIONES: {`${((totals.totalComission / totals.total) * 100).toFixed(2)} %`}</h5>
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>TOTAL:</h4>
            <h5 className="text-vw-light">{`${totals.totalComission.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>BASE:</h4>
            <h5 className="text-vw-green">{`${totals.totalComissionBase.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h4>IVA:</h4>
            <h5 className="text-vw-red">{`${totals.totalComissionIva.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col"><h6 className="text-white">CASH</h6></th>
                <th scope="col"><h6 className="text-white">TPV</h6></th>
                <th scope="col"><h6 className="text-white">QR</h6></th>
                <th scope="col"><h6 className="text-white">PDA</h6></th>
                <th scope="col"><h6 className="text-white">GRATIS</h6></th>
                <th scope="col"><h6 className="text-white">CREDITO</h6></th>
                <th scope="col"><h6 className="text-white">MANUAL</h6></th>
                <th scope="col"><h6 className="text-white">DEVOLUCIONES</h6></th>
                <th scope="col"><h6 className="text-white">TOTAL</h6></th>
              </tr>
            </thead>
            <tbody>
              {
                itemsArray.map((item, index) => {
                  if (this.anyItemSale(totals, index)) {
                    return (
                      <tr key={item}>
                        <th scope="row"><h6 className="text-left text-vw-dark">{getLockersName(item)}</h6></th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('CASH')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('CASH')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TPV')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TPV')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('QR')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('QR')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('PDA')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('PDA')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('FREE')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('FREE')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('CREDIT')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('CREDIT')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('TOKEN')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('TOKEN')[index]} u`}</th>
                        <th scope="row">{`${(totals.amountsPerMethod.get('DEVOLUTION')[index]).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totals.qtyPerMethod.get('DEVOLUTION')[index]} u`}</th>
                        <th scope="row">{`${(totals.totalsPerItem.get(item)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })} / ${totalsQty.totalsPerItemQty.get(item)} u`}</th>
                      </tr>
                    );
                  }
                  return null;
                })
              }
              <tr>
                <th scope="row"><h5 className="text-left text-vw-dark">TOTALES</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('CASH').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TPV').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('QR').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('PDA').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('FREE').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('CREDIT').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('TOKEN').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.totalsPerMethod.get('DEVOLUTION').reduce((acc, currVal) => acc + currVal)).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
                <th scope="row"><h5 className="text-vw-light">{`${(totals.total).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h5></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {
    const { role, type } = this.props.auth

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              ANÁLISIS DE VENTAS
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <SalesFilterForm />
        </Box>
        <div>
          {this.renderSalesTotalsRevenue()}
        </div>
        <div>
          {this.renderSalesTotalsSale()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchSalesCSV: bindActionCreators(fetchSalesCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InternalSalesView)


