import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchMachines } from '../actions/machines'
import { fetchIncidences } from '../actions/incidences'
import { fetchSales } from '../actions/sales'

import MachineStatusBadge from '../components/MachineStatusBadge'
import MachineMap from '../components/MachineMap'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import PropertyLabel from '../components/PropertyLabel'

import { getMachinePicture, getMachineCoordinates } from '../utils/machines'

import { getMachineStatus, getMachineCirclePicture } from '../utils/machines'

class HomeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = { machine: null }
  }

  componentDidMount() {
    const { fetchMachines, fetchIncidences, fetchSales } = this.props

    const today = new Date().toISOString().substring(0, 10)

    fetchMachines()
    fetchIncidences('', '', 'OPEN', '', '', '')
    fetchSales('', '', today, today, '', '', '', '')
  }

  onMachineClick = (index, childProps) => {
    this.setState({ machine: childProps.machine })
  }

  computeSalesTotals() {
    const { sales: { sales } } = this.props

    let total = 0

    let totalsPerItem = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    const itemIndices = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 1],
      ['LOCKERBWS', 2],
      ['LOCKERCWS', 3],
      ['LOCKERBNM', 4],
      ['LOCKERCNM', 5],
      ['LOCKERBWM', 6],
      ['LOCKERCWM', 7],
      ['LOCKERBNL', 8],
      ['LOCKERCNL', 9],
      ['LOCKERBWL', 10],
      ['LOCKERCWL', 11],
      ['LOCKERBNXL', 12],
      ['LOCKERCNXL', 13],
      ['LOCKERBWXL', 14],
      ['LOCKERCWXL', 15],
      ['LOCKERBN2XL', 16],
      ['LOCKERCN2XL', 17],
      ['LOCKERBW2XL', 18],
      ['LOCKERCW2XL', 19],
      ['LOCKERBN3XL', 20],
      ['LOCKERCN3XL', 21],
      ['LOCKERBW3XL', 22],
      ['LOCKERCW3XL', 23],
      ['TUMBONAS', 24],
      ['FLOTADORES', 25],
      ['FLOTADORESHALFDAY', 26],
      ['FASTPASS', 27]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['FREE', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['CREDIT', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    for (let sale of sales) {

      let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

      for (let item of sale.Items) {
        let itemTotal = 0

        if (Math.abs(item.qty) > 1) {
          itemTotal = item.price

          if (sale.Amount != 0) {
            total += itemTotal

            totalsPerItem.set(item.item, totalsPerItem.get(item.item) + (itemTotal / Math.abs(item.qty)))
          }
        } else {
          itemTotal = item.price

          if (sale.Amount != 0) {
            total += itemTotal

            totalsPerItem.set(item.item, totalsPerItem.get(item.item) + itemTotal)
          }
        }

        qtyPerSaleMethod[itemIndices.get(item.item)] += item.qty
      }

      qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
    }

    return {
      total: total,
      totalsPerItem: totalsPerItem,
      qtyPerMethod: qtyPerMethod
    }
  }

  computeItemsTotals() {
    const { sales: { sales } } = this.props

    let totalQty = 0

    let totalsPerItemQty = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 0],
      ['LOCKERBWS', 0],
      ['LOCKERCWS', 0],
      ['LOCKERBNM', 0],
      ['LOCKERCNM', 0],
      ['LOCKERBWM', 0],
      ['LOCKERCWM', 0],
      ['LOCKERBNL', 0],
      ['LOCKERCNL', 0],
      ['LOCKERBWL', 0],
      ['LOCKERCWL', 0],
      ['LOCKERBNXL', 0],
      ['LOCKERCNXL', 0],
      ['LOCKERBWXL', 0],
      ['LOCKERCWXL', 0],
      ['LOCKERBN2XL', 0],
      ['LOCKERCN2XL', 0],
      ['LOCKERBW2XL', 0],
      ['LOCKERCW2XL', 0],
      ['LOCKERBN3XL', 0],
      ['LOCKERCN3XL', 0],
      ['LOCKERBW3XL', 0],
      ['LOCKERCW3XL', 0],
      ['TUMBONAS', 0],
      ['FLOTADORES', 0],
      ['FLOTADORESHALFDAY', 0],
      ['FASTPASS', 0]
    ])

    for (let sale of sales) {
      for (let item of sale.Items) {
        let itemTotal = item.qty

        totalQty += itemTotal
        totalsPerItemQty.set(item.item, totalsPerItemQty.get(item.item) + itemTotal)
      }
    }

    return {
      totalQty: totalQty,
      totalsPerItemQty: totalsPerItemQty
    }
  }

  computeMachineStatuses() {
    const { machines: { machines } } = this.props

    let statuses = new Map([
      ['ONLINE', 0],
      ['LOCKERS LLENOS', 0],
      ['SIN TICKET', 0],
      ['OFFLINE', 0],
      ['SLEEP', 0],
      ['UNKNOWN', 0]
    ])

    for (let machine of machines) {
      let status = getMachineStatus(machine)

      statuses.set(status, statuses.get(status) + 1)
    }

    return statuses
  }

  computeTotalLockers() {
    const { machines: { machines } } = this.props

    let totalLockers = 0

    for (let machine of machines) {
      if (machine.LastIndex != null && machine.FirstIndex != null) {
        totalLockers += parseInt(machine.LastIndex)
      }
    }

    return Math.abs(totalLockers)
  }

  render() {
    const { machines, incidences, sales, auth } = this.props

    if (machines.error) {
      return (<ErrorMessage message={machines.error.message} />)
    }

    if (incidences.error) {
      return (<ErrorMessage message={incidences.error.message} />)
    }

    if (sales.error) {
      return (<ErrorMessage message={sales.error.message} />)
    }

    if (!machines.machines || machines.loading ||
      !incidences.incidences || incidences.loading ||
      !sales.sales || sales.loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let totals = this.computeSalesTotals()
    let totalsQty = this.computeItemsTotals()
    let MachineStatuses = this.computeMachineStatuses()
    let LockersTotal = this.computeTotalLockers()

    return (
      <div>
        <h1>
          <span className="text-vw-dark">TABLERO DE </span>
          <span className="text-vw-light">INFORMACIÓN</span>
          <hr className="bg-vw-light" />
        </h1>

        <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
          <div class="row" style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}>
            <h5 style={{ marginRight: '2vw' }}>{(((totalsQty.totalQty - totalsQty.totalsPerItemQty.get('TUMBONAS')) / LockersTotal) * 100).toFixed(2)} %</h5>
            <h5 style={{ marginRight: '2vw' }}>OCUPACIÓN DE TAQUILLAS</h5>
            <h5>{totalsQty.totalQty - totalsQty.totalsPerItemQty.get('TUMBONAS')} / {LockersTotal}</h5>
          </div>
        </div>
        {auth.type !== 'PRODUCTION' &&
          <div class="row">
            {/*<img src="/images/realtime.svg" width="20%" alt="Realtime" />*/}
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
                <h5>IMPORTE</h5>
                <hr className="bg-vw-light" />
                {totals.totalsPerItem.get('LOCKERBNS') > 0 ?
                  (<PropertyLabel name="Básica" value={`${(totals.totalsPerItem.get('LOCKERBNS')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCNS') > 0 ?
                  (<PropertyLabel name="Básica Carga" value={`${(totals.totalsPerItem.get('LOCKERCNS')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBWS') > 0 ?
                  (<PropertyLabel name="Básica XL" value={`${(totals.totalsPerItem.get('LOCKERBWS')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCWS') > 0 ?
                  (<PropertyLabel name="Básica Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCWS')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBNM') > 0 ?
                  (<PropertyLabel name="Grande" value={`${(totals.totalsPerItem.get('LOCKERBNM')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCNM') > 0 ?
                  (<PropertyLabel name="Grande Carga" value={`${(totals.totalsPerItem.get('LOCKERCNM')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBWM') > 0 ?
                  (<PropertyLabel name="Grande XL" value={`${(totals.totalsPerItem.get('LOCKERBWM')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCWM') > 0 ?
                  (<PropertyLabel name="Grande Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCWM')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBNL') > 0 ?
                  (<PropertyLabel name="Jumbo" value={`${(totals.totalsPerItem.get('LOCKERBNL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCNL') > 0 ?
                  (<PropertyLabel name="Jumbo Carga" value={`${(totals.totalsPerItem.get('LOCKERCNL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBWL') > 0 ?
                  (<PropertyLabel name="Jumbo XL" value={`${(totals.totalsPerItem.get('LOCKERBWL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCWL') > 0 ?
                  (<PropertyLabel name="Jumbo Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCWL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBNXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4" value={`${(totals.totalsPerItem.get('LOCKERBNXL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCNXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga" value={`${(totals.totalsPerItem.get('LOCKERCNXL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBWXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 XL" value={`${(totals.totalsPerItem.get('LOCKERBWXL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCWXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCWXL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBN2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5" value={`${(totals.totalsPerItem.get('LOCKERBN2XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCN2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga" value={`${(totals.totalsPerItem.get('LOCKERCN2XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBW2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 XL" value={`${(totals.totalsPerItem.get('LOCKERBW2XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCW2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCW2XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBN3XL') > 0 ?
                  (<PropertyLabel name="Ski" value={`${(totals.totalsPerItem.get('LOCKERBN3XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCN3XL') > 0 ?
                  (<PropertyLabel name="Ski Carga" value={`${(totals.totalsPerItem.get('LOCKERCN3XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERBW3XL') > 0 ?
                  (<PropertyLabel name="Ski XL" value={`${(totals.totalsPerItem.get('LOCKERBW3XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('LOCKERCW3XL') > 0 ?
                  (<PropertyLabel name="Ski Carga XL" value={`${(totals.totalsPerItem.get('LOCKERCW3XL')).toFixed(2)} €`} />) : ("")
                }
                {totals.totalsPerItem.get('TUMBONAS') > 0 ?
                  (<PropertyLabel name="Tumbonas" value={`${(totals.totalsPerItem.get('TUMBONAS')).toFixed(2)} €`} />) : ("")
                }
                <hr className="bg-vw-light" />
                <PropertyLabel name="TOTAL DIARIO" value={`${(totals.total).toFixed(2)} €`} />
              </div>
            </div>
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
                <h5>CANTIDAD</h5>
                <hr className="bg-vw-light" />
                {totalsQty.totalsPerItemQty.get('LOCKERBNS') > 0 ?
                  (<PropertyLabel name="Básica" value={`${totalsQty.totalsPerItemQty.get('LOCKERBNS')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCNS') > 0 ?
                  (<PropertyLabel name="Básica Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCNS')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBWS') > 0 ?
                  (<PropertyLabel name="Básica XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBWS')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCWS') > 0 ?
                  (<PropertyLabel name="Básica Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCWS')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBNM') > 0 ?
                  (<PropertyLabel name="Grande" value={`${totalsQty.totalsPerItemQty.get('LOCKERBNM')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCNM') > 0 ?
                  (<PropertyLabel name="Grande Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCNM')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBWM') > 0 ?
                  (<PropertyLabel name="Grande XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBWM')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCWM') > 0 ?
                  (<PropertyLabel name="Grande Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCWM')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBNL') > 0 ?
                  (<PropertyLabel name="Jumbo" value={`${totalsQty.totalsPerItemQty.get('LOCKERBNL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCNL') > 0 ?
                  (<PropertyLabel name="Jumbo Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCNL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCNL') > 0 ?
                  (<PropertyLabel name="Jumbo Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCNL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBWL') > 0 ?
                  (<PropertyLabel name="Jumbo XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBWL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCWL') > 0 ?
                  (<PropertyLabel name="Jumbo Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCWL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBNXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4" value={`${totalsQty.totalsPerItemQty.get('LOCKERBNXL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCNXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCNXL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBWXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBWXL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCWXL') > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCWXL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBN2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5" value={`${totalsQty.totalsPerItemQty.get('LOCKERBN2XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCN2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCN2XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBW2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBW2XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCW2XL') > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCW2XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBN3XL') > 0 ?
                  (<PropertyLabel name="Ski" value={`${totalsQty.totalsPerItemQty.get('LOCKERBN3XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCN3XL') > 0 ?
                  (<PropertyLabel name="Ski Carga" value={`${totalsQty.totalsPerItemQty.get('LOCKERCN3XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERBW3XL') > 0 ?
                  (<PropertyLabel name="Ski XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERBW3XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('LOCKERCW3XL') > 0 ?
                  (<PropertyLabel name="Ski Carga XL" value={`${totalsQty.totalsPerItemQty.get('LOCKERCW3XL')} u`} />) : ("")
                }
                {totalsQty.totalsPerItemQty.get('TUMBONAS') > 0 ?
                  (<PropertyLabel name="Tumbonas" value={`${totalsQty.totalsPerItemQty.get('TUMBONAS')} u`} />) : ("")
                }
                <hr className="bg-vw-light" />
                <PropertyLabel name="TOTAL DIARIO" value={`${totalsQty.totalQty} u`} />
              </div>
            </div>
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
                <h5>ESTADO</h5>
                <hr className="bg-vw-light" />
                <PropertyLabel name="ONLINE"
                  value={`${MachineStatuses.get('ONLINE')} / ${machines.machines.length}`} />
                <PropertyLabel name="LOCKERS LLENOS"
                  value={`${MachineStatuses.get('LOCKERS LLENOS')} / ${machines.machines.length}`} />
                <PropertyLabel name="SIN TICKET"
                  value={`${MachineStatuses.get('SIN TICKET')} / ${machines.machines.length}`} />
                <PropertyLabel name="SLEEP"
                  value={`${MachineStatuses.get('SLEEP')} / ${machines.machines.length}`} />
                <hr className="bg-vw-light" />
                <PropertyLabel name="OFFLINE"
                  value={`${MachineStatuses.get('OFFLINE')} / ${machines.machines.length}`} />
              </div>
            </div>
            <div className="col col-12 col-lg-3">
              <div className="p-3 mb-3 bg-light-blue rounded text-white box-shadow">
                <h5>EXPEDICION MANUAL</h5>
                <hr className="bg-vw-light" />
                {totals.qtyPerMethod.get('TOKEN')[0] > 0 ?
                  (<PropertyLabel name="Básica" value={`${totals.qtyPerMethod.get('TOKEN')[0]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[0] / totalsQty.totalsPerItemQty.get('LOCKERBNS')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[1] > 0 ?
                  (<PropertyLabel name="Básica Carga" value={`${totals.qtyPerMethod.get('TOKEN')[1]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[1] / totalsQty.totalsPerItemQty.get('LOCKERCNS')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[2] > 0 ?
                  (<PropertyLabel name="Básica XL" value={`${totals.qtyPerMethod.get('TOKEN')[2]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[2] / totalsQty.totalsPerItemQty.get('LOCKERBWS')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[3] > 0 ?
                  (<PropertyLabel name="Básica Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[3]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[3] / totalsQty.totalsPerItemQty.get('LOCKERCWS')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[4] > 0 ?
                  (<PropertyLabel name="Grande" value={`${totals.qtyPerMethod.get('TOKEN')[4]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[4] / totalsQty.totalsPerItemQty.get('LOCKERBNM')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[5] > 0 ?
                  (<PropertyLabel name="Grande Carga" value={`${totals.qtyPerMethod.get('TOKEN')[5]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[5] / totalsQty.totalsPerItemQty.get('LOCKERCNM')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[6] > 0 ?
                  (<PropertyLabel name="Grande XL" value={`${totals.qtyPerMethod.get('TOKEN')[6]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[6] / totalsQty.totalsPerItemQty.get('LOCKERBWM')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[7] > 0 ?
                  (<PropertyLabel name="Grande Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[7]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[7] / totalsQty.totalsPerItemQty.get('LOCKERCWM')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[8] > 0 ?
                  (<PropertyLabel name="Jumbo" value={`${totals.qtyPerMethod.get('TOKEN')[8]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[8] / totalsQty.totalsPerItemQty.get('LOCKERBNL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[9] > 0 ?
                  (<PropertyLabel name="Jumbo Carga" value={`${totals.qtyPerMethod.get('TOKEN')[9]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[9] / totalsQty.totalsPerItemQty.get('LOCKERCNL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[10] > 0 ?
                  (<PropertyLabel name="Jumbo XL" value={`${totals.qtyPerMethod.get('TOKEN')[10]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[10] / totalsQty.totalsPerItemQty.get('LOCKERBWL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[11] > 0 ?
                  (<PropertyLabel name="Jumbo Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[11]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[11] / totalsQty.totalsPerItemQty.get('LOCKERCWL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[12] > 0 ?
                  (<PropertyLabel name="Jumbo 4" value={`${totals.qtyPerMethod.get('TOKEN')[12]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[12] / totalsQty.totalsPerItemQty.get('LOCKERBNXL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[13] > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga" value={`${totals.qtyPerMethod.get('TOKEN')[13]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[13] / totalsQty.totalsPerItemQty.get('LOCKERCNXL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[14] > 0 ?
                  (<PropertyLabel name="Jumbo 4 XL" value={`${totals.qtyPerMethod.get('TOKEN')[14]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[14] / totalsQty.totalsPerItemQty.get('LOCKERBWXL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[15] > 0 ?
                  (<PropertyLabel name="Jumbo 4 Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[15]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[15] / totalsQty.totalsPerItemQty.get('LOCKERCWXL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[16] > 0 ?
                  (<PropertyLabel name="Jumbo 5" value={`${totals.qtyPerMethod.get('TOKEN')[16]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[16] / totalsQty.totalsPerItemQty.get('LOCKERBN2XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[17] > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga" value={`${totals.qtyPerMethod.get('TOKEN')[17]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[17] / totalsQty.totalsPerItemQty.get('LOCKERCN2XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[18] > 0 ?
                  (<PropertyLabel name="Jumbo 5 XL" value={`${totals.qtyPerMethod.get('TOKEN')[18]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[18] / totalsQty.totalsPerItemQty.get('LOCKERBW2XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[19] > 0 ?
                  (<PropertyLabel name="Jumbo 5 Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[19]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[19] / totalsQty.totalsPerItemQty.get('LOCKERCW2XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[20] > 0 ?
                  (<PropertyLabel name="Ski" value={`${totals.qtyPerMethod.get('TOKEN')[20]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[20] / totalsQty.totalsPerItemQty.get('LOCKERBN3XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[21] > 0 ?
                  (<PropertyLabel name="Ski Carga" value={`${totals.qtyPerMethod.get('TOKEN')[21]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[21] / totalsQty.totalsPerItemQty.get('LOCKERCN3XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[22] > 0 ?
                  (<PropertyLabel name="Ski XL" value={`${totals.qtyPerMethod.get('TOKEN')[22]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[22] / totalsQty.totalsPerItemQty.get('LOCKERBW3XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[23] > 0 ?
                  (<PropertyLabel name="Ski Carga XL" value={`${totals.qtyPerMethod.get('TOKEN')[23]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[23] / totalsQty.totalsPerItemQty.get('LOCKERCW3XL')) * 100).toFixed(2)} %`} />) : ("")
                }
                {totals.qtyPerMethod.get('TOKEN')[24] > 0 ?
                  (<PropertyLabel name="Tumbonas" value={`${totals.qtyPerMethod.get('TOKEN')[24]} u - ${parseFloat((totals.qtyPerMethod.get('TOKEN')[24] / totalsQty.totalsPerItemQty.get('TUMBONAS')) * 100).toFixed(2)} %`} />) : ("")
                }
              </div>
              {auth.role === 'ADMIN' &&
                <div className="bg-white rounded text-warning box-shadow">
                  <i className="fas fa-exclamation-triangle" style={{ display: 'inline-block', width: '2rem', marginTop: '0.5rem', marginLeft: '2rem', height: '2rem' }}></i>
                  <h4 style={{ display: 'inline-block', marginLeft: '1rem' }}><b>{incidences.incidences.length}</b> incidentes</h4>
                </div>
              }
            </div>
          </div>
        }
        <br></br>
        <br></br>
        <h1>
          <span className="text-vw-dark">LOCALIZACIÓN </span>
          <span className="text-vw-light">DE MÁQUINAS</span>
          <hr className="bg-vw-light" />
        </h1>
        <br></br>

        <div className="mb-3 rounded box-shadow" style={{ width: '100%', height: '400px' }}>
          <MachineMap machines={machines.machines}
            onMachineClick={this.onMachineClick} />
        </div>
        {auth.type !== 'PRODUCTION' &&
          <Box title="Detalles de la máquina" icon="info-circle">
            {!this.state.machine ? (
              <p className="mt-2 text-center">
                Selecciona una máquina para ver sus detalles.
              </p>
            ) : (
              <div className="row m-2">
                <div className="col col-sm-3 text-center">
                  <img src={getMachinePicture(this.state.machine.Type)} alt="Máquina" width="35%" style={{ marginLeft: '20%' }} />

                  <MachineStatusBadge machine={this.state.machine} />
                </div>
                <div className="col col-sm-9">
                  <PropertyLabel name="Número de serie"
                    value={this.state.machine.Serial} />
                  <PropertyLabel name="Cliente"
                    value={this.state.machine.Customer?.Fullname || 'None'} />
                  <Link to={`/machines/${this.state.machine.Serial}`}>
                    <button type="button" className="btn bg-vw-light text-white mt-3">
                      Gestionar &rarr;
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </Box>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines,
  incidences: state.incidences,
  sales: state.sales,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchIncidences: bindActionCreators(fetchIncidences, dispatch),
  fetchSales: bindActionCreators(fetchSales, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
