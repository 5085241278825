import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../store'

import { updateRule, fetchRules, deleteRule } from '../actions/rules'

class RulesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleblockConfirmShown: false,
      ruleId: null,
      ruleActive: null,
    };

    this.updateRuleblock = this.updateRuleblock.bind(this)

    this.ruleblockConfirmShow = this.ruleblockConfirmShow.bind(this)
    this.ruleblockConfirmToggle = this.ruleblockConfirmToggle.bind(this)
  }

  goTo(id, e) {
    history.push(`/rules/${id}/edit`)
  }

  getTypeName(type) {
    switch (type) {
      case "MaxDayDiscount": return "Descuento por días"
      case "AlwaysDiscount": return "Descuento fijo"
      default: return "None"
    }
  }

  getLockerName(type) {
    switch (type) {
      case "ALL": return "TODAS"
      case "2PAX": return "2 Equipos de esquí"
      case "3PAX": return "3 Equipos de esquí"
      case "4PAX": return "4 Equipos de esquí"
      default: return "None"
    }
  }

  handleRuleblockClick = (rule, e) => {
    e.stopPropagation();
    this.ruleblockConfirmShow(rule._id, rule.Active);
  }

  updateRuleblock() {
    const { updateRule, fetchRules } = this.props

    let body = {};
    body.Active = !this.state.ruleActive

    updateRule(this.state.ruleId, body)
      .then((answer) => {
        fetchRules();
      })
      .catch((error) => {
        console.error(error);
      });

    this.ruleblockConfirmToggle()
  }

  ruleblockConfirmShow(id, active) {
    this.setState({
      ruleblockConfirmShown: true,
      ruleId: id,
      ruleActive: active
    })
  }

  ruleblockConfirmToggle() {
    this.setState({ ruleblockConfirmShown: !this.state.ruleblockConfirmShown })
  }

  handleDeleteRule(ruleId, e) {
    e.stopPropagation(); // Prevent row click navigation
  
    const { deleteRule, fetchRules } = this.props;
  
    if (window.confirm('¿Estás seguro de que deseas eliminar esta regla?')) {
      deleteRule(ruleId)
        .then((answer) => {
          fetchRules(); // Refresh the rules list after deletion
        })
        .catch((error) => {
          console.error('Error deleting rule:', error);
        });
    }
  }

  render() {

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Activa</th>
              <th scope="col">Tipo</th>
              <th scope="col">Días máximos</th>
              <th scope="col">Descuento (%)</th>
              <th scope="col">Tipo de taquilla</th>
              <th scope="col">Condiciones</th>
              <th scope="col">Fecha de creación</th>
              <th scope="col">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.rules.map(rule => (
              <tr key={rule._id} onClick={e => this.goTo(rule._id, e)}>
                <td>{rule.Name}</td>
                <td>
                  <div className="flex align-items-center justify-content-center">
                    <div className="mr-5">
                      {rule.Active ? 'SI' : 'NO'}
                    </div>
                    <button className={`btn text-white ${rule.Active ? 'bg-danger' : 'bg-success'}`}
                      onClick={(e) => this.handleRuleblockClick(rule, e)}>
                      <i className={`fas mr-1 ${rule.Active ? 'fa-ban' : 'fa-check'}`}></i>{rule.Active ? 'Deshabilitar' : 'Habilitar'}
                    </button>
                  </div>
                </td>
                <td>{this.getTypeName(rule.Type)}</td>
                <td>{rule.MaxDays} {rule.MaxDays === 1 ? 'día' : 'días'}</td>
                <td>{rule.DiscountPercentage}%</td>
                <td>{this.getLockerName(rule.Locker)}</td>
                <td>{rule.Condition}</td>
                <td>{(new Date(rule.TimeStamp)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={(e) => this.handleDeleteRule(rule._id, e)}
                  >
                    <i className="fas fa-trash"></i> Eliminar
                  </button>
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
        <div>
          <Modal isOpen={this.state.ruleblockConfirmShown}
            toggle={this.ruleblockConfirmToggle}>
            <ModalHeader toggle={this.ruleblockConfirmToggle}>
              {this.state.ruleActive != null && this.state.ruleActive ?
                (<p className="text-center">Deshabilitar regla</p>) : (<p className="text-center">Habilitar regla</p>)
              }
            </ModalHeader>
            <ModalBody>
              {this.state.ruleActive != null && this.state.ruleActive ?
                (
                  <div className="text-center">
                    <p>¿Estás seguro de deshabilitar la regla?</p>
                    <br></br>
                    <p>-Aunque el agregador la tenga asignada en su ficha esta no será aplicada en el precio.</p>
                  </div>
                ) : (
                  <div className="text-center">
                    <p>¿Estás seguro de habilitar la venta del agregador?</p>
                    <br></br>
                    <p>-Si el agregador la tiene asignada en su ficha esta será aplicada en el precio.</p>
                  </div>
                )
              }
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="info mr-5" onClick={this.updateRuleblock}>
                Confirmar
              </Button>
              <Button color="danger" onClick={this.ruleblockConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rules: state.rules.rules,
})

const mapDispatchToProps = dispatch => ({
  updateRule: bindActionCreators(updateRule, dispatch),
  fetchRules: bindActionCreators(fetchRules, dispatch),
  deleteRule: bindActionCreators(deleteRule, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RulesList)


