import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AppHeader from './AppHeader'

import HomeView from '../views/HomeView'
import MachinesView from '../views/MachinesView'
import MachineDetailsView from '../views/MachineDetailsView'
import MachineAddForm from '../forms/MachineAddForm'
import MachineEditForm from '../forms/MachineEditForm'
import ContractsView from '../views/ContractsView'
import ContractCreateForm from '../forms/ContractCreateForm'
import ContractEditForm from '../forms/ContractEditForm'
import ContractDetailsView from '../views/ContractDetailsView'
import CustomersView from '../views/CustomersView'
import CustomerAddForm from '../forms/CustomerAddForm'
import CustomerEditForm from '../forms/CustomerEditForm'
import CustomerDetailsView from '../views/CustomerDetailsView'
import CustomerCashAddForm from '../forms/CustomerCashAddForm'
import CustomerCashEditForm from '../forms/CustomerCashEditForm'
import CustomerCashDetailsView from '../views/CustomerCashDetailsView'
import CustomerPDAAddForm from '../forms/CustomerPDAAddForm'
import CustomerPDAEditForm from '../forms/CustomerPDAEditForm'
import CustomerPDADetailsView from '../views/CustomerPDADetailsView'
import CustomerGroupAddForm from '../forms/CustomerGroupAddForm'
import CustomerGroupEditForm from '../forms/CustomerGroupEditForm'
import CustomerGroupDetailsView from '../views/CustomerGroupDetailsView'
import IncidencesView from '../views/IncidencesView'
import IncidenceCreateForm from '../forms/IncidenceCreateForm'
import IncidenceEditForm from '../forms/IncidenceEditForm'
import IncidenceActuationForm from '../forms/IncidenceActuationForm'
import IncidenceResolutionForm from '../forms/IncidenceResolutionForm'
import IncidenceDetailsView from '../views/IncidenceDetailsView'
import LogisticsView from '../views/LogisticsView'
import NewShipmentForm from '../forms/NewShipmentForm'
import LogisticsShipmentView from '../views/LogisticsShipmentView'
import SalesView from '../views/SalesView'
import OcupationView from '../views/OcupationView'
import OcupationCustomerView from '../views/OcupationCustomerView'
import InternalSalesView from '../views/InternalSalesView'
import OnlineSalesView from '../views/OnlineSalesView'
import SaleDetailsView from '../views/SaleDetailsView'
import UsersView from '../views/UsersView'
import UserAddForm from '../forms/UserAddForm'
import UserEditForm from '../forms/UserEditForm'
import UserDetailsView from '../views/UserDetailsView'
import UpdatesView from '../views/UpdatesView'
import UpdateAddForm from '../forms/UpdateAddForm'
import UpdateEditForm from '../forms/UpdateEditForm'
import UpdateDetailsView from '../views/UpdateDetailsView'
import NotFoundView from '../views/NotFoundView'
import AuditView from '../views/AuditView'
import ShiftsView from '../views/ShiftsView'
import MatrixsView from '../views/MatrixsView'
import MatrixDetailsView from '../views/MatrixDetailsView'
import ArqueosView from '../views/ArqueosView'
import ArqueosSalesView from '../views/ArqueosSalesView'
import PromotersView from '../views/PromotersView'
import PromoterEditForm from '../forms/PromoterEditForm'
import PromoterCreateForm from '../forms/PromoterCreateForm'
import PromoterDetailsView from '../views/PromoterDetailsView'
import ObjectivesView from '../views/ObjectivesView'
import ObjectiveEditForm from '../forms/ObjectiveEditForm'
import ObjectiveCreateForm from '../forms/ObjectiveCreateForm'
import ObjectiveDetailsView from '../views/ObjectiveDetailsView'
import ModulesView from '../views/ModulesView'
import ModuleDetailsView from '../views/ModuleDetailsView'
import ModuleAddForm from '../forms/ModuleAddForm'
import ModuleEditForm from '../forms/ModuleEditForm'
import PinView from '../views/PinView'
import QRsView from '../views/QRsView'
import QRDetailsView from '../views/QRDetailsView'
import QRAddForm from '../forms/QRAddForm'
import QREditForm from '../forms/QREditForm'
import WithdrawalView from '../views/WithdrawalView'
import WithdrawalEditForm from '../forms/WithdrawalEditForm'
import WithdrawalEditBalanceForm from '../forms/WithdrawalEditBalanceForm'
import LockerSaleView from '../views/LockerSaleView'
import TPVsView from '../views/TPVsView'
import UniversalUpdatesView from '../views/UniversalUpdatesView'
import FaultsView from '../views/FaultsView'
import LowBluetoothView from '../views/LowBluetoothView'
import ReplacementsView from '../views/ReplacementsView'
import ReplacementsEditForm from '../forms/ReplacementsEditForm'
import FaultsReportView from '../views/FaultsReportView'
import PasswordRecoveryView from '../views/PasswordRecoveryView'
import PasswordAssignView from '../views/PasswordAssignView'
import SupportTicketsView from '../views/SupportTicketsView'
import SupportTicketAddForm from '../forms/SupportTicketAddForm'
import PDAsView from '../views/PDAsView'
import PDADetailsView from '../views/PDADetailsView'
import PDAEditForm from '../forms/PDAEditForm'
import PDAAddForm from '../forms/PDAAddForm'
import OpeningsView from '../views/OpeningsView'
import PinRecoverView from '../views/PinRecoverView'
import OperatorsView from '../views/OperatorsView'
import OperatorDetailsView from '../views/OperatorDetailsView'
import OperatorAddForm from '../forms/OperatorAddForm'
import OperatorEditForm from '../forms/OperatorEditForm'
import CalendarsView from '../views/CalendarsView'
import CalendarDetailsView from '../views/CalendarDetailsView'
import CalendarAddForm from '../forms/CalendarAddForm'
import RulesView from '../views/RulesView'
import RuleAddForm from '../forms/RuleAddForm'
import RuleEditForm from '../forms/RuleEditForm'
import RentalsView from '../views/RentalsView'
import RentalEditForm from '../forms/RentalEditForm'
import RentalDetailsView from '../views/RentalDetailsView'
import ArticlesView from '../views/ArticlesView'
import ArqueosBaqueiraView from '../views/ArqueosBaqueiraView'
import ArqueosBaqueiraDetailsView from '../views/ArqueosBaqueiraDetailsView'

class App extends React.Component {
       render() {
              return (
                     <div>
                            <AppHeader />
                            <div className="container pt-3">
                                   <Switch>
                                          <Route path="/" exact
                                                 component={HomeView} />
                                          <Route path="/machines" exact
                                                 component={MachinesView} />
                                          <Route path="/machines/add" exact
                                                 component={MachineAddForm} />
                                          <Route path="/machines/:id/edit"
                                                 component={MachineEditForm} />
                                          <Route path="/machines/:id"
                                                 component={MachineDetailsView} />

                                          <Route path="/contracts" exact
                                                 component={ContractsView} />
                                          <Route path="/contracts/create" exact
                                                 component={ContractCreateForm} />
                                          <Route path="/contracts/:id/edit"
                                                 component={ContractEditForm} />
                                          <Route path="/contracts/:id"
                                                 component={ContractDetailsView} />

                                          <Route path="/customers" exact
                                                 component={CustomersView} />
                                          <Route path="/customers/add" exact
                                                 component={CustomerAddForm} />
                                          <Route path="/customers/:id/edit"
                                                 component={CustomerEditForm} />
                                          <Route path="/customers/:id"
                                                 component={CustomerDetailsView} />

                                          <Route path="/customerscash/:id/add"
                                                 component={CustomerCashAddForm} />
                                          <Route path="/customerscash/:id/edit"
                                                 component={CustomerCashEditForm} />
                                          <Route path="/customerscash/:id"
                                                 component={CustomerCashDetailsView} />

                                          <Route path="/customerspda/:id/add"
                                                 component={CustomerPDAAddForm} />
                                          <Route path="/customerspda/:id/edit"
                                                 component={CustomerPDAEditForm} />
                                          <Route path="/customerspda/:id"
                                                 component={CustomerPDADetailsView} />

                                          <Route path="/customersgroup/add"
                                                 component={CustomerGroupAddForm} />
                                          <Route path="/customersgroup/:id/edit"
                                                 component={CustomerGroupEditForm} />
                                          <Route path="/customersgroup/:id"
                                                 component={CustomerGroupDetailsView} />

                                          <Route path="/incidences" exact
                                                 component={IncidencesView} />
                                          <Route path="/incidences/create" exact
                                                 component={IncidenceCreateForm} />
                                          <Route path="/incidences/:number/edit" exact
                                                 component={IncidenceEditForm} />
                                          <Route path="/incidences/:number/actuation" exact
                                                 component={IncidenceActuationForm} />
                                          <Route path="/incidences/:number/resolution" exact
                                                 component={IncidenceResolutionForm} />
                                          <Route path="/incidences/:number"
                                                 component={IncidenceDetailsView} />

                                          <Route path="/logistics" exact
                                                 component={LogisticsView} />
                                          <Route path="/logistics/newshipping" exact
                                                 component={NewShipmentForm} />
                                          <Route path="/logistics/:number"
                                                 component={LogisticsShipmentView} />

                                          <Route path="/sales" exact
                                                 component={SalesView} />
                                          <Route path="/internalsales" exact
                                                 component={InternalSalesView} />
                                          <Route path="/online-sales" exact
                                                 component={OnlineSalesView} />
                                          <Route path="/sales/:id"
                                                 component={SaleDetailsView} />

                                          <Route path="/users" exact
                                                 component={UsersView} />
                                          <Route path="/users/add" exact
                                                 component={UserAddForm} />
                                          <Route path="/users/:number/edit"
                                                 component={UserEditForm} />
                                          <Route path="/users/:number"
                                                 component={UserDetailsView} />

                                          <Route path="/updates" exact
                                                 component={UpdatesView} />
                                          <Route path="/updates/add" exact
                                                 component={UpdateAddForm} />
                                          <Route path="/updates/:id/edit"
                                                 component={UpdateEditForm} />
                                          <Route path="/updates/:id"
                                                 component={UpdateDetailsView} />

                                          <Route path="/audit" exact
                                                 component={AuditView} />

                                          <Route path="/shifts" exact
                                                 component={ShiftsView} />

                                          <Route path="/matrixs" exact
                                                 component={MatrixsView} />
                                          <Route path="/matrixs/:id"
                                                 component={MatrixDetailsView} />

                                          <Route path="/arqueos" exact
                                                 component={ArqueosView} />
                                          <Route path="/arqueossales" exact
                                                 component={ArqueosSalesView} />

                                          <Route path="/promoters" exact
                                                 component={PromotersView} />
                                          <Route path="/promoters/:number/edit"
                                                 component={PromoterEditForm} />
                                          <Route path="/promoters/create" exact
                                                 component={PromoterCreateForm} />
                                          <Route path="/promoters/:number"
                                                 component={PromoterDetailsView} />

                                          <Route path="/objectives" exact
                                                 component={ObjectivesView} />
                                          <Route path="/objectives/:customer/edit"
                                                 component={ObjectiveEditForm} />
                                          <Route path="/objectives/create" exact
                                                 component={ObjectiveCreateForm} />
                                          <Route path="/objectives/:customer"
                                                 component={ObjectiveDetailsView} />

                                          <Route path="/modules" exact
                                                 component={ModulesView} />
                                          <Route path="/modules/add" exact
                                                 component={ModuleAddForm} />
                                          <Route path="/modules/:Code/edit"
                                                 component={ModuleEditForm} />
                                          <Route path="/modules/:Code"
                                                 component={ModuleDetailsView} />

                                          <Route path="/pin" exact
                                                 component={PinView} />

                                          <Route path="/qrs" exact
                                                 component={QRsView} />
                                          <Route path="/qrs/add" exact
                                                 component={QRAddForm} />
                                          <Route path="/qrs/:id/edit"
                                                 component={QREditForm} />
                                          <Route path="/qrs/:id"
                                                 component={QRDetailsView} />

                                          <Route path="/pdas" exact
                                                 component={PDAsView} />
                                          <Route path="/pdas/add" exact
                                                 component={PDAAddForm} />
                                          <Route path="/pdas/:id/edit"
                                                 component={PDAEditForm} />
                                          <Route path="/pdas/:id"
                                                 component={PDADetailsView} />

                                          <Route path="/withdrawals" exact
                                                 component={WithdrawalView} />
                                          <Route path="/withdrawals/:id/edit"
                                                 component={WithdrawalEditForm} />
                                          <Route path="/withdrawals/:id/balance"
                                                 component={WithdrawalEditBalanceForm} />

                                          <Route path="/lockersale" exact
                                                 component={LockerSaleView} />

                                          <Route path="/tpvs" exact
                                                 component={TPVsView} />

                                          <Route path="/universalupdates" exact
                                                 component={UniversalUpdatesView} />

                                          <Route path="/faults" exact
                                                 component={FaultsView} />
                                          <Route path="/faults/report" exact
                                                 component={FaultsReportView} />

                                          <Route path="/lowbluetooth" exact
                                                 component={LowBluetoothView} />

                                          <Route path="/ocupation" exact
                                                 component={OcupationView} />
                                          
                                          <Route path="/ocupationaccumulated" exact
                                                 component={OcupationCustomerView} />

                                          <Route path="/replacements" exact
                                                 component={ReplacementsView} />
                                          <Route path="/replacements/:id/edit"
                                                 component={ReplacementsEditForm} />

                                          <Route path="/passwordrecovery" exact
                                                 component={PasswordRecoveryView} />
                                          <Route path="/passwordassign"
                                                 component={PasswordAssignView} />

                                          <Route path="/supporttickets" exact
                                                 component={SupportTicketsView} />
                                          <Route path="/supportticketadd" exact
                                                 component={SupportTicketAddForm} />

                                          <Route path="/openings" exact
                                                 component={OpeningsView} />

                                          <Route path="/pinrecovers" exact
                                                 component={PinRecoverView} />

                                          <Route path="/operators" exact
                                                 component={OperatorsView} />
                                          <Route path="/operators/add" exact
                                                 component={OperatorAddForm} />
                                          <Route path="/operators/:id/edit"
                                                 component={OperatorEditForm} />
                                          <Route path="/operators/:id"
                                                 component={OperatorDetailsView} />

                                          <Route path="/calendars" exact
                                                 component={CalendarsView} />
                                          <Route path="/calendars/add" exact
                                                 component={CalendarAddForm} />
                                          <Route path="/calendars/:id"
                                                 component={CalendarDetailsView} />

                                          <Route path="/rules" exact
                                                 component={RulesView} />
                                          <Route path="/rules/add" exact
                                                 component={RuleAddForm} />
                                          <Route path="/rules/:id/edit"
                                                 component={RuleEditForm} />

                                          <Route path="/rentals" exact
                                                 component={RentalsView} />
                                          <Route path="/rentals/:id/edit"
                                                 component={RentalEditForm} />
                                          <Route path="/rentals/:id"
                                                 component={RentalDetailsView} />

                                          <Route path="/articles" exact
                                                 component={ArticlesView} />

                                          <Route path="/arqueosbaqueira" exact
                                                 component={ArqueosBaqueiraView} />
                                          <Route path="/arqueosbaqueira/:id"
                                                 component={ArqueosBaqueiraDetailsView} />

                                          <Route path="*"
                                                 component={NotFoundView} />
                                   </Switch>
                            </div>
                     </div>
              )
       }
}

export default App
