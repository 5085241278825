import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchCustomerPDA } from '../actions/customers'
import AssignedZoneList from '../components/AssignedZoneList'

class CustomerPDADetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchCustomerPDA(this.props.match.params.id)
  }

  render() {
    const { role, type, customer, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!customer || loading || !customer.Customer || !customer.Customer.Fullname) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">USUARIO PDA CONTROL </span>
              <span className="text-vw-dark">{customer.Fullname}</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-4 col-md-4 text-right">
              <Link to={`/customerspda/${customer._id}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user">
              <PropertyLabel name="Nombre completo"
                value={customer.Fullname} />
              <PropertyLabel name="E-Mail"
                value={customer.Email} />
              <PropertyLabel name="Cliente General"
                value={customer.Customer.Fullname} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Agregador" icon="user">
              <PropertyLabel name="Nombre"
                value={customer.Operator?.Name || 'None'} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Editor" icon="user">
              <PropertyLabel name="Última edición" ml
                value={customer.Executor} />
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customers.customer,
  loading: state.customers.loading,
  error: state.customers.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchCustomerPDA: bindActionCreators(fetchCustomerPDA, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPDADetailsView)
