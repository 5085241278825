import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { history } from '../store';
import { fetchArqueosBaqueira, sendArqueoBaqueira } from '../actions/arqueosbaqueira'

class ArqueosBaqueiraList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        locker_name: '',
        end_date: '',
        status: '', // New status filter
      },
      loadingArqueoId: null, // Track which arqueo is being processed
    };
  }

  goTo(id, e) {
    history.push(`/arqueosbaqueira/${id}`);
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  // Function to handle JSON download
  downloadJson = (arqueo, e) => {
    e.stopPropagation(); // Prevent the row click event from being triggered

    const jsonString = JSON.stringify(arqueo, null, 2); // Convert to JSON string with indentation
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    // Create a link and trigger a download
    const link = document.createElement('a');
    link.href = url;
    link.download = `arqueo-${arqueo.locker_name}-${new Date(arqueo.date).toLocaleDateString()}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to send Arqueo Baqueira
  sendArqueoBaqueira = async (arqueo) => {
    this.setState({ loadingArqueoId: arqueo._id });

    try {
      // Simulate the API call
      const response = await this.props.sendArqueoBaqueira(arqueo._id);

      // Update the status in the arqueos array
      if (response) {
        this.props.fetchArqueosBaqueira();
      } else {
        // Handle failure if needed
        console.error("Error sending Arqueo:", response.message);
      }
    } catch (error) {
      console.error("Failed to send Arqueo:", error);
    } finally {
      // Stop loading
      this.setState({ loadingArqueoId: null });
    }
  };

  render() {
    const { filters, loadingArqueoId } = this.state;
    const { arqueosbaqueira } = this.props;

    // Apply filters to filter the arqueos array
    const filteredArqueos = arqueosbaqueira.filter(arqueo => {
      const { locker_name, end_date, status } = filters;

      // Apply the locker_name filter
      if (locker_name && !arqueo.locker_name.toLowerCase().includes(locker_name.toLowerCase())) {
        return false;
      }

      // Apply the end_date filter
      if (end_date && new Date(arqueo.date).toLocaleDateString() !== new Date(end_date).toLocaleDateString()) {
        return false;
      }

      // Apply the status filter
      if (status) {
        const isStatusTrue = status === 'true';
        if (arqueo.status !== isStatusTrue) {
          return false;
        }
      }

      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Fecha de Arqueo</th>
              <th scope="col">Zona</th>
              <th scope="col">Sudiba ERP</th>
              <th scope="col">Descarga</th>
              <th scope="col">Subida</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="date"
                  value={filters.end_date}
                  onChange={e => this.handleFilterChange('end_date', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Zona"
                  value={filters.locker_name}
                  onChange={e => this.handleFilterChange('locker_name', e.target.value)}
                />
              </th>
              <th scope="col">
                <select
                  value={filters.status}
                  onChange={e => this.handleFilterChange('status', e.target.value)}
                >
                  <option value="">Todas</option>
                  <option value="true">Correctas</option>
                  <option value="false">Fallidas</option>
                </select>
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredArqueos
              .sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
              .map(arqueo => (
                <tr key={arqueo._id} onClick={e => this.goTo(arqueo._id, e)}>
                  <td>{new Date(arqueo.date).toLocaleDateString()}</td>
                  <td>{arqueo.locker_name}</td>
                  <td
                    className={`bg-${arqueo.status ? 'success' : 'danger'}`}
                    onClick={e => {
                      e.stopPropagation(); // Prevent row click event
                      alert(`Response: ${arqueo.response}`); // Display arqueo.response in a browser message
                    }}
                  >
                    {arqueo.status === true ? 'Correcta' : arqueo.status === false ? 'Fallida' : 'None'}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary ml-2"
                      onClick={e => this.downloadJson(arqueo, e)}
                    >
                      Descargar
                    </button>
                  </td>
                  <td>
                    {arqueo.status === false ? (
                      loadingArqueoId === arqueo._id ? (
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Ejecutando...</span>
                        </div>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={e => {
                            e.stopPropagation();
                            this.sendArqueoBaqueira(arqueo);
                          }}
                        >
                          Enviar
                        </button>
                      )
                    ) : (
                      <button className="btn btn-secondary" disabled>
                        Enviado
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

// Example of connecting Redux actions
const mapDispatchToProps = (dispatch) => ({
  sendArqueoBaqueira: bindActionCreators(sendArqueoBaqueira, dispatch),
  fetchArqueosBaqueira: bindActionCreators(fetchArqueosBaqueira, dispatch),
});

export default connect(null, mapDispatchToProps)(ArqueosBaqueiraList);
