import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '../components/Box';
import PropertyLabel from '../components/PropertyLabel';
import ErrorMessage from '../components/ErrorMessage';
import { fetchArqueoBaqueira } from '../actions/arqueosbaqueira';
import moment from 'moment';

class ArqueosBaqueiraDetailsView extends React.Component {

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchArqueoBaqueira(id);
  }

  render() {
    const { arqueobaqueira, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueobaqueira || loading) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-10 col-md-10">
            <h1>
              <span className="text-vw-light">ARQUEO </span>
              <span className="text-vw-dark">ZONA: </span>
              <span className="text-vw-light">{arqueobaqueira.locker_name} </span>
              <span className="text-vw-dark">FECHA: </span>
              <span className="text-vw-light">{moment(arqueobaqueira.date).format('DD/MM/YYYY')}</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2 d-flex justify-content-end">
            <Link to="/arqueosBaqueira">
              <button className="btn bg-vw-light text-white">
                <i className="fas fa-arrow-left"></i>
                <span className="d-none d-sm-inline ml-1">Volver</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Información General" icon="info-circle">
              <PropertyLabel name="Proyecto" value={arqueobaqueira.project} />
              <PropertyLabel name="Nombre de Taquilla" value={arqueobaqueira.locker_name} />
              <PropertyLabel name="Fecha de Inicio" value={moment(arqueobaqueira.start_date).format('DD/MM/YYYY HH:mm')} />
              <PropertyLabel name="Fecha de Fin" value={moment(arqueobaqueira.end_date).format('DD/MM/YYYY HH:mm')} />
              <PropertyLabel name="Fecha de Arqueo" value={moment(arqueobaqueira.date).format('DD/MM/YYYY HH:mm')} />
            </Box>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <Box title="Totales de Pago" icon="coins">
              <div className="row mb-2">
                {arqueobaqueira.payments_totals.map((payment, index) => (
                  <div key={index} className="ticket-box col-xs-12 col-sm-4 col-md-4">
                    <Box title={payment.payment_form_id} icon="coins">
                      <PropertyLabel name="Cuenta" value={payment.cash_account} />
                      <PropertyLabel name="Forma de Pago" value={payment.payment_form_id} />
                      <PropertyLabel name="Importe Declarado" value={`${(payment.imp_dec / 100).toFixed(2)} €`} />
                      <PropertyLabel name="Importe Facturado" value={`${(payment.imp_fac / 100).toFixed(2)} €`} />
                      <PropertyLabel name="Diferencia" value={`${(payment.diff / 100).toFixed(2)} €`} />
                    </Box>
                  </div>
                ))}
              </div>
            </Box>
          </div>
        </div>
        <Box title="Tickets" icon="ticket-alt">
          <div className="row mb-2">
            {arqueobaqueira.tickets.map((ticket, index) => (
              <div key={index} className="ticket-box col-xs-12 col-sm-4 col-md-4">
                <Box title={`Ticket ${index + 1}`} icon="file-invoice">
                  <PropertyLabel name="Número de Serie" value={ticket.serial_number} />
                  <PropertyLabel name="Compañía" value={ticket.company} />
                  <PropertyLabel name="Proyecto" value={ticket.project} />
                  <PropertyLabel name="Delegación" value={ticket.delega} />
                  <PropertyLabel name="Nombre de la Tienda" value={ticket.store_name} />
                  <PropertyLabel name="Tipo de Documento" value={ticket.tipdoc} />
                  <PropertyLabel name="Agregador" value={ticket.channel} />
                  <PropertyLabel name="NIF" value={ticket.nif_channel} />
                  <PropertyLabel name="Nombre del Cliente" value={ticket.name} />
                  <PropertyLabel name="Correo Electrónico" value={ticket.email} />
                  <PropertyLabel name="Número de Reserva" value={ticket.booking_number} />
                  <PropertyLabel name="País" value={ticket.country_name} />
                  <PropertyLabel name="Importe Total" value={`${(ticket.total_amount / 100).toFixed(2)} €`} />
                  <PropertyLabel name="Idioma" value={ticket.language} />
                  <Box title="Detalles de Ticket" icon="list">
                    {ticket.details.map((detail, detailIndex) => (
                      <div key={detailIndex} className="d-flex justify-content-between mt-2">
                        <span>
                          {moment(detail.StartDate).format('DD/MM/YYYY')} - {moment(detail.EndDate).format('DD/MM/YYYY')}
                        </span>
                        <span>{(detail.Price).toFixed(2)} €</span>
                      </div>
                    ))}
                  </Box>
                  <Box title="Líneas de Ticket" icon="align-justify">
                    {ticket.lines.map((line, lineIndex) => (
                      <div key={lineIndex} className="d-flex justify-content-between mt-2">
                        <span>Taquilla: {line.locker_number}</span>
                        <span>Desc: {line.art_desc}</span>
                        <span>Code: {line.art_code}</span>
                        <span>Importe: {(line.amount / 100).toFixed(2)} €</span>
                      </div>
                    ))}
                  </Box>
                  <Box title="Pagos de Ticket" icon="credit-card">
                    {ticket.ticket_payments.map((payment, paymentIndex) => (
                      <div key={paymentIndex} className="d-flex justify-content-between mt-2">
                        <span>Forma de Pago: {payment.payment_form_id}</span>
                        <span>Importe: {(payment.payment_amount / 100).toFixed(2)} €</span>
                        <span>Cuenta: {payment.cash_account}</span>
                      </div>
                    ))}
                  </Box>
                </Box>
              </div>
            ))}
          </div>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  arqueobaqueira: state.arqueosbaqueira.arqueobaqueira,
  loading: state.arqueosbaqueira.loading,
  error: state.arqueosbaqueira.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArqueoBaqueira: (id) => dispatch(fetchArqueoBaqueira(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosBaqueiraDetailsView);
