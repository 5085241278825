import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchModule } from '../actions/modules'

import AssignedPairingList from '../components/AssignedPairingList'

class ModuleDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchModule(this.props.match.params.Code)
  }

  getModulePicture(type) {

    switch (type) {
      case "VLM01": {
        return '/images/modules/module_6.png'
      }
      case "VLM02": {
        return '/images/modules/module_5.png'
      }
      case "VLM04": {
        return '/images/modules/module_4.png'
      }
      case "VLM06": {
        return '/images/modules/module_3.png'
      }
      case "VLM08": {
        return '/images/modules/module_2.png'
      }
      case "VLM10": {
        return '/images/modules/module_2.png'
      }
      case "VLM12": {
        return '/images/modules/module_1.png'
      }
      case "VLK04": {
        return '/images/modules/kiosc_2.png'
      }
      case "VLK06": {
        return '/images/modules/kiosc_1.png'
      }
      case "VLK08": {
        return '/images/modules/kiosc_3.png'
      }
    }


  }

  render() {
    const { module, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!module || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-dark">DETALLES DEL </span>
              <span className="text-vw-light">MÓDULO </span>
              <span className="text-vw-dark">{module.Code}</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 text-right">
            <div className="row flex justify-content-around">
              <Link to={`/modules/${module.Code}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              <Link to={`/modules/add`}>
                <button className="btn bg-vw-dark text-white mr-1">
                  <i className="fas fa-plus mr-1"></i>Añadir Módulo
                </button>
              </Link>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <img src={this.getModulePicture((module.Code).substring(0, 5))} alt="Máquina" width="35%" style={{ marginLeft: '20%', marginRight: '20%' }} />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8">
            <Box title="Configuración" icon="cubes">
              <div className="row">
                <div className="col">
                  <PropertyLabel name="Modo"
                    value={module.Mode || 'B'} />
                </div>
                <div className="col">
                  <PropertyLabel name="Ancho"
                    value={module.Wide || 'N'} />
                </div>
                <div className="col">
                  <PropertyLabel name="Espressif"
                    value={module.Espressif || 0} />
                </div>
                <div className="col">
                  <PropertyLabel name="Género"
                    value={module.Gender || 'None'} />
                </div>
              </div>
            </Box>

            <AssignedPairingList RegisterLockers={module.RegisterLockers} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  module: state.modules.module,
  loading: state.modules.loading,
  error: state.modules.error
})

const mapDispatchToProps = dispatch => ({
  fetchModule: bindActionCreators(fetchModule, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDetailsView)
