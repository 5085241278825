import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as dateFormat from 'dateformat'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import { history } from '../store'

import { fetchOperator } from '../actions/operators'
import AssignedRulesList from '../components/AssignedRulesList'

class OperatorDetailsView extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.props.fetchOperator(this.props.match.params.id);
  }

  goToCalendar(id, e) {
    e.stopPropagation();
    history.push(`/calendars/${id}`)
  }

  getInvoiceName(type) {
    switch (type) {
      case 'Invoice': return 'Factura'
      case 'Note': return 'Albarán'
      case 'Free': return 'No Contable'
    }
  }

  render() {
    const { operator, error, loading, auth, submitting, role, type, category } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!operator || loading) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col col-12 col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">AGREGADOR  </span>
              <span className="text-vw-dark">{operator.Name}</span>
            </h1>
          </div>
          {((role === 'ADMIN' && (type === 'GOD' || type === 'ROOT')) || category === 'BAQUEIRA') &&
            <div className="col col-12 col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-end">
              <div className="d-flex justify-content-end">
                <Link to={`/operators/${operator._id}/edit`}>
                  <button className="btn bg-vw-light text-white mr-1">
                    <i className="fas fa-edit mr-1"></i>Editar
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Configuración" icon="sliders-h">
              <PropertyLabel name="Venta habilitada"
                value={operator.Blocked ? 'NO' : 'SI'} />
              <PropertyLabel name="Modelo de facturación"
                value={this.getInvoiceName(operator.InvoiceType)} />
              <PropertyLabel name="Canal Propio Baqueira"
                value={operator.External ? 'SI' : 'NO'} />
              <PropertyLabel name="Comisión"
                value={operator.Comission} />
              <PropertyLabel name="NIF/CIF"
                value={operator.Nif} />
              <PropertyLabel name="Dirección"
                value={operator.Address} />
              <PropertyLabel name="País"
                value={operator.Country} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Credenciales API" icon="user">
              <PropertyLabel name="Bearer Token"
                value={operator._id} />
            </Box>
            <Box title="Calendario de precios" icon="calendar">
              <PropertyLabel name="Calendario"
                value={operator.Calendar ? operator.Calendar.Name : 'None'} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Reglas de negocio" icon="pencil-ruler">
              <AssignedRulesList Rules={operator.BusinessRules} />
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  operator: state.operators.operator,
  loading: state.operators.loading,
  error: state.operators.error,
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  category: state.auth.category
});

const mapDispatchToProps = dispatch => ({
  fetchOperator: bindActionCreators(fetchOperator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorDetailsView);
