import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as dateFormat from 'dateformat'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'

import { fetchMatrixs } from '../actions/matrixs'
import { fetchMachines } from '../actions/machines'
import { addAudit } from '../actions/audits'

import ErrorMessage from '../components/ErrorMessage'

import { renderInputField, renderSelectField } from '../utils/forms'

class PinView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phoneValue: "",
      emailValue: "",
      nameValue: "",
      lockerResult: 0,
      lockerType: "",
      lockerPass: "000000",
      machineResult: "XXXX",
      machineFirstIndex: 0,
      ticketRender: []
    };

  }

  componentDidMount() {

    this.props.fetchMatrixs('', '', '', '');
    this.props.fetchMachines();
  }

  renderResultPhone(e) {

    const { matrixs, machines } = this.props

    const ticketRender = [];

    let searchLockerResult = 0
    let lockerSelected = ""
    let searchLockerPass = ""
    let searchMachineResult = ""
    let searchMachineFirstIndex = 0

    let printIndex = 0

    matrixs.map(matrix => {
      matrix.Locker.map((lock, index) => {

        if (lock.Phone === this.state.phoneValue || ('+' + lock.Phone) === this.state.phoneValue) {
          machines.map(machine => {
            if (machine.Serial === matrix.Serial) {

              searchMachineFirstIndex = parseInt(machine.FirstIndex)
              searchMachineResult = machine.Serial

              const audit = {
                TimeStamp: new Date(),
                Machine: machine._id,
                Categoria: 'PIN_RECOVER',
                Evento: 'RECUPERACIÓN DE PIN PDA',
                Description: 'Taquilla: ' + ((index) + searchMachineFirstIndex),
                WorkerSession: '000000',
                Customer: machine.Customer._id
              };

              this.props.addAudit(audit);
            }
          })
          searchLockerResult = (index) + searchMachineFirstIndex
          searchLockerPass = lock.Pass + "#"
          lockerSelected = lock.Mode + lock.Wide + lock.Type


          switch (lockerSelected) {
            case "BNS":
              lockerSelected = "Básica";
              break;
            case "CNS":
              lockerSelected = "Básica Carga";
              break;
            case "BWS":
              lockerSelected = "Básica XL";
              break;
            case "BWS":
              lockerSelected = "Básica Carga XL";
              break;
            case "BNM":
              lockerSelected = "Grande";
              break;
            case "CNM":
              lockerSelected = "Grande Carga";
              break;
            case "BWM":
              lockerSelected = "Grande XL";
              break;
            case "CWM":
              lockerSelected = "Grande Carga XL";
              break;
            case "BNL":
              lockerSelected = "Jumbo";
              break;
            case "CNL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BNXL":
              lockerSelected = "Jumbo";
              break;
            case "CNXL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWXL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWXL":
              lockerSelected = "JumboCarga XL";
              break;
            case "BN2XL":
              lockerSelected = "Jumbo";
              break;
            case "CN2XL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BW2XL":
              lockerSelected = "Jumbo XL";
              break;
            case "CW2XL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BN3XL":
              lockerSelected = "2 Equipos de Esquí";
              break;
            case "CN3XL":
              lockerSelected = "3 Equipos de Esquí";
              break;
            case "BW3XL":
              lockerSelected = "4 Equipos de Esquí";
              break;
            case "CW3XL":
              lockerSelected = "Ski Carga XL";
              break;
          }

          printIndex++;


          ticketRender.push(
            <div className="col col-12 col-lg-6 ml-auto mr-auto">
              <Box title="Ticket">
                <div className="lockerTicket" id={printIndex}>
                  <button className="btn bg-dark text-white button-center" onClick={e => this.printLockerTicket(e, e.currentTarget.parentNode.id)} disabled={(this.state.phoneValue === "")}>
                    <i className="fas fa-print mr-1"></i>Imprimir Ticket
                  </button>
                  <hr className="bg-vw-light" />
                  <h5 className="lockTitle"><span className="text-vw-dark">TAQUILLA</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerResult}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">PIN</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerPass}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">TIPO</span></h5>
                  <h1 className="lockTitle"><span>{lockerSelected}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">MÁQUINA</span></h5>
                  <h1 className="lockTitle"><span>{searchMachineResult}</span></h1>
                  <hr className="bg-vw-light" />
                </div>
              </Box>
            </div>
          );

        }
      })
    })

    if (printIndex == 0) {
      ticketRender.push(
        <div className="row">
          <div className="col col-12">
            <h1>TELEFONO NO ENCONTRADO / PHONE NOT FOUND</h1>
          </div>
        </div>
      );
    }

    this.setState({ ticketRender: ticketRender, machineResult: searchMachineResult, machineFirstIndex: searchMachineFirstIndex, lockerResult: searchLockerResult, lockerPass: searchLockerPass, lockerType: lockerSelected })
  }

  renderResultEmail(e) {

    const { matrixs, machines } = this.props

    const ticketRender = [];

    let searchLockerResult = 0
    let lockerSelected = ""
    let searchLockerPass = ""
    let searchMachineResult = ""
    let searchMachineFirstIndex = 0

    let printIndex = 0

    matrixs.map(matrix => {
      matrix.Locker.map((lock, index) => {

        if (lock.Email === this.state.emailValue) {
          machines.map(machine => {
            if (machine.Serial === matrix.Serial) {

              searchMachineFirstIndex = parseInt(machine.FirstIndex)
              searchMachineResult = machine.Serial

              const audit = {
                TimeStamp: new Date(),
                Machine: machine._id,
                Categoria: 'PIN_RECOVER',
                Evento: 'RECUPERACIÓN DE PIN PDA',
                Description: 'Taquilla: ' + ((index) + searchMachineFirstIndex),
                WorkerSession: '000000',
                Customer: machine.Customer._id
              };

              this.props.addAudit(audit);
            }
          })
          searchLockerResult = (index) + searchMachineFirstIndex
          searchLockerPass = lock.Pass + "#"
          lockerSelected = lock.Mode + lock.Wide + lock.Type


          switch (lockerSelected) {
            case "BNS":
              lockerSelected = "Básica";
              break;
            case "CNS":
              lockerSelected = "Básica Carga";
              break;
            case "BWS":
              lockerSelected = "Básica XL";
              break;
            case "BWS":
              lockerSelected = "Básica Carga XL";
              break;
            case "BNM":
              lockerSelected = "Grande";
              break;
            case "CNM":
              lockerSelected = "Grande Carga";
              break;
            case "BWM":
              lockerSelected = "Grande XL";
              break;
            case "CWM":
              lockerSelected = "Grande Carga XL";
              break;
            case "BNL":
              lockerSelected = "Jumbo";
              break;
            case "CNL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BNXL":
              lockerSelected = "Jumbo";
              break;
            case "CNXL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWXL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWXL":
              lockerSelected = "JumboCarga XL";
              break;
            case "BN2XL":
              lockerSelected = "Jumbo";
              break;
            case "CN2XL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BW2XL":
              lockerSelected = "Jumbo XL";
              break;
            case "CW2XL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BN3XL":
              lockerSelected = "2 Equipos de Esquí";
              break;
            case "CN3XL":
              lockerSelected = "3 Equipos de Esquí";
              break;
            case "BW3XL":
              lockerSelected = "4 Equipos de Esquí";
              break;
            case "CW3XL":
              lockerSelected = "Ski Carga XL";
              break;
          }

          printIndex++;


          ticketRender.push(
            <div className="col col-12 col-lg-6 ml-auto mr-auto">
              <Box title="Ticket">
                <div className="lockerTicket" id={printIndex}>
                  <button className="btn bg-dark text-white button-center" onClick={e => this.printLockerTicket(e, e.currentTarget.parentNode.id)} disabled={(this.state.emailValue === "")}>
                    <i className="fas fa-print mr-1"></i>Imprimir Ticket
                  </button>
                  <hr className="bg-vw-light" />
                  <h5 className="lockTitle"><span className="text-vw-dark">TAQUILLA</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerResult}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">PIN</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerPass}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">TIPO</span></h5>
                  <h1 className="lockTitle"><span>{lockerSelected}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">MÁQUINA</span></h5>
                  <h1 className="lockTitle"><span>{searchMachineResult}</span></h1>
                  <hr className="bg-vw-light" />
                </div>
              </Box>
            </div>
          );

        }
      })
    })

    if (printIndex == 0) {
      ticketRender.push(
        <div className="row">
          <div className="col col-12">
            <h1>EMAIL NO ENCONTRADO / EMAIL NOT FOUND</h1>
          </div>
        </div>
      );
    }

    this.setState({ ticketRender: ticketRender, machineResult: searchMachineResult, machineFirstIndex: searchMachineFirstIndex, lockerResult: searchLockerResult, lockerPass: searchLockerPass, lockerType: lockerSelected })
  }

  renderResultName(e) {

    const { matrixs, machines } = this.props

    const ticketRender = [];

    let searchLockerResult = 0
    let lockerSelected = ""
    let searchLockerPass = ""
    let searchMachineResult = ""
    let searchMachineFirstIndex = 0

    let printIndex = 0

    matrixs.map(matrix => {
      matrix.Locker.map((lock, index) => {

        if (lock.Name === this.state.nameValue) {
          machines.map(machine => {
            if (machine.Serial === matrix.Serial) {

              searchMachineFirstIndex = parseInt(machine.FirstIndex)
              searchMachineResult = machine.Serial

              const audit = {
                TimeStamp: new Date(),
                Machine: machine._id,
                Categoria: 'PIN_RECOVER',
                Evento: 'RECUPERACIÓN DE PIN PDA',
                Description: 'Taquilla: ' + ((index) + searchMachineFirstIndex),
                WorkerSession: '000000',
                Customer: machine.Customer._id
              };

              this.props.addAudit(audit);
            }
          })
          searchLockerResult = (index) + searchMachineFirstIndex
          searchLockerPass = lock.Pass + "#"
          lockerSelected = lock.Mode + lock.Wide + lock.Type


          switch (lockerSelected) {
            case "BNS":
              lockerSelected = "Básica";
              break;
            case "CNS":
              lockerSelected = "Básica Carga";
              break;
            case "BWS":
              lockerSelected = "Básica XL";
              break;
            case "BWS":
              lockerSelected = "Básica Carga XL";
              break;
            case "BNM":
              lockerSelected = "Grande";
              break;
            case "CNM":
              lockerSelected = "Grande Carga";
              break;
            case "BWM":
              lockerSelected = "Grande XL";
              break;
            case "CWM":
              lockerSelected = "Grande Carga XL";
              break;
            case "BNL":
              lockerSelected = "Jumbo";
              break;
            case "CNL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BNXL":
              lockerSelected = "Jumbo";
              break;
            case "CNXL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BWXL":
              lockerSelected = "Jumbo XL";
              break;
            case "CWXL":
              lockerSelected = "JumboCarga XL";
              break;
            case "BN2XL":
              lockerSelected = "Jumbo";
              break;
            case "CN2XL":
              lockerSelected = "Jumbo Carga";
              break;
            case "BW2XL":
              lockerSelected = "Jumbo XL";
              break;
            case "CW2XL":
              lockerSelected = "Jumbo Carga XL";
              break;
            case "BN3XL":
              lockerSelected = "2 Equipos de Esquí";
              break;
            case "CN3XL":
              lockerSelected = "3 Equipos de Esquí";
              break;
            case "BW3XL":
              lockerSelected = "4 Equipos de Esquí";
              break;
            case "CW3XL":
              lockerSelected = "Ski Carga XL";
              break;
          }

          printIndex++;


          ticketRender.push(
            <div className="col col-12 col-lg-6 ml-auto mr-auto">
              <Box title="Ticket">
                <div className="lockerTicket" id={printIndex}>
                  <button className="btn bg-dark text-white button-center" onClick={e => this.printLockerTicket(e, e.currentTarget.parentNode.id)} disabled={(this.state.nameValue === "")}>
                    <i className="fas fa-print mr-1"></i>Imprimir Ticket
                  </button>
                  <hr className="bg-vw-light" />
                  <h5 className="lockTitle"><span className="text-vw-dark">TAQUILLA</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerResult}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">PIN</span></h5>
                  <h1 className="lockTitle"><span>{searchLockerPass}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">TIPO</span></h5>
                  <h1 className="lockTitle"><span>{lockerSelected}</span></h1>
                  <h5 className="lockTitle"><span className="text-vw-dark">MÁQUINA</span></h5>
                  <h1 className="lockTitle"><span>{searchMachineResult}</span></h1>
                  <hr className="bg-vw-light" />
                </div>
              </Box>
            </div>
          );

        }
      })
    })

    if (printIndex == 0) {
      ticketRender.push(
        <div className="row">
          <div className="col col-12">
            <h1>NOMBRE NO ENCONTRADO / NAME NOT FOUND</h1>
          </div>
        </div>
      );
    }

    this.setState({ ticketRender: ticketRender, machineResult: searchMachineResult, machineFirstIndex: searchMachineFirstIndex, lockerResult: searchLockerResult, lockerPass: searchLockerPass, lockerType: lockerSelected })
  }

  printLockerTicket(e, printIndex) {

    const content = document.getElementById(`${printIndex}`).innerHTML;
    const printWindow = window.open('', '', 'height=400,width=600');
    printWindow.document.write('<html><head><title>PIN TICKET</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();

  }

  render() {

    const { matrixs, machines } = this.props

    if (!matrixs || !machines) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">RECUPERAR </span>
              <span className="text-vw-light">PIN</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Búsqueda por teléfono" icon="filter">
              <div className="row">
                <div className="col">
                  <input className="full" placeHolder="Introduce un teléfono" type="tel" value={this.state.phoneValue} onChange={(e) => { this.setState({ phoneValue: e.target.value }) }} />
                </div>
              </div>
              <br></br>
              <div className="row justify-content-center">
                <button className="btn bg-success text-white" onClick={e => this.renderResultPhone(e)} disabled={(this.state.phoneValue === "")}>
                  <i className="fas fa-sync mr-1"></i>Buscar
                </button>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Búsqueda por email" icon="filter">
              <div className="row">
                <div className="col">
                  <input className="full" placeHolder="Introduce un email" type="email" value={this.state.emailValue} onChange={(e) => { this.setState({ emailValue: e.target.value }) }} />
                </div>
              </div>
              <br></br>
              <div className="row justify-content-center">
                <button className="btn bg-success text-white" onClick={e => this.renderResultEmail(e)} disabled={(this.state.emailValue === "")}>
                  <i className="fas fa-sync mr-1"></i>Buscar
                </button>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Búsqueda por nombre" icon="filter">
              <div className="row">
                <div className="col">
                  <input className="full" placeHolder="Introduce un nombre" type="text" value={this.state.nameValue} onChange={(e) => { this.setState({ nameValue: e.target.value }) }} />
                </div>
              </div>
              <br></br>
              <div className="row justify-content-center">
                <button className="btn bg-success text-white" onClick={e => this.renderResultName(e)} disabled={(this.state.nameValue === "")}>
                  <i className="fas fa-sync mr-1"></i>Buscar
                </button>
              </div>
            </Box>
          </div>
        </div>
        <div className="row">
          {this.state.ticketRender}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  matrixs: state.matrixs.matrixs,
  error: state.matrixs.error,
  loading: state.matrixs.loading,
  machines: state.machines.machines,
  role: state.auth.role,
  id: state.auth.id,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  addAudit: bindActionCreators(addAudit, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PinView)
