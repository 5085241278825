import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'
import { fetchOperators } from '../actions/operators'
import Box from '../components/Box'
import { addCustomerPDA } from '../actions/customers'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class CustomerPDAAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchOperators } = this.props

    fetchOperators()

  }

  submit(props) {

    const { match } = this.props

    props.Executor = this.props.fullname

    return this.props.addCustomerPDA(props, match.params.id)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit, operators } = this.props

    if (!operators) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">USUARIO PDA</span>
        </h1>
        <div className="row">
          <div className="col">
            <Box title="Cliente" icon="user">
              <Field name="Fullname"
                type="text"
                component={renderInputField}
                label="Nombre completo" />
              <Field name="Email"
                type="text"
                component={renderInputField}
                label="E-mail" />
              <Field name="Password"
                type="password"
                component={renderInputField}
                label="Password" />
            </Box>
          </div>
          <div className="col">
            <Box title="Agregador vinculado (Solo Baqueira)" icon="user">
              <Field name="Operator"
                component={renderSelectField}
                label="Agregador">
                <option value="">Selecciona un agregador...</option>
                {operators &&
                  operators
                    .sort((a, b) => a.Name.localeCompare(b.Name))
                    .map((operator) => (
                      <option value={operator._id} key={operator._id}>
                        {operator.Name}
                      </option>
                    ))}
              </Field>
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

CustomerPDAAddForm = reduxForm({
  form: 'CustomerPDAAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/customers/`)
  }
})(CustomerPDAAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  operators: state.operators.operators,
})

const mapDispatchToProps = dispatch => ({
  addCustomerPDA: bindActionCreators(addCustomerPDA, dispatch),
  fetchOperators: bindActionCreators(fetchOperators, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPDAAddForm)

